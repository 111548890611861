import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  BankingChannel,
  PanelType,
  FixedDepositAmount
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  numberWithCommas

} from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalBody, Alert } from "reactstrap";
import Numpad from "../../components/custom/Numpad";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";

import TickIcon from "../../assets/img/assets/tick.svg";
import CopyIcon from "../../assets/img/v3/copy.png"

/// <summary>
/// Author: -
/// </summary>
const NormalDeposit = (props) => {
  const { t, i18n } = useTranslation();
  let _userData = useSelector((state) => state.authState.userData);
  let _dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    errors,
    clearErrors,
    setError
  } = useForm();


  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [bankAccountId, setBankAccountId] = useState("");
  const [receiptImage, setReceiptImage] = useState();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [isReloadSuccess, setIsReloadSuccess] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);
  const [reloadDate, setReloadDate] = useState();
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [imageFileSize, setImageFileSize] = useState(0);
  const ONE_MB_IN_BYTES = 1048576;
  const fileSizeLimit_InMB = 20;
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const _ALERT_TIMEOUT = 3000;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bankIsSelected, setBankIsSelected] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [amountValue, setAmountValue] = useState("");
  const fixedAmount = FixedDepositAmount;

  const [depositReference, setDepositReference] = useState("");

  //useEffect

  useEffect(() => {
    init();
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  async function init() {
    getBankAccount();
    getDepositReference();
  }

  async function getBankAccount() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_BANK_ACCOUNT +
      "?deposit=" +
      true
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBankAccountOption(responseJson[ApiKey._API_DATA_KEY].sort((a, b) => a.bankNormalRank - b.bankNormalRank));
    }
  }

  async function getDepositReference() {
    let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=depositReference";
    let settingJson = await ApiEngine.get(apiUrl);
    if (settingJson[ApiKey._API_SUCCESS_KEY] && settingJson[ApiKey._API_DATA_KEY] != null) {
      let data = settingJson[ApiKey._API_DATA_KEY];
      setDepositReference(data);
    }
  }

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const fileSizeInMB = file.size / ONE_MB_IN_BYTES;
      setImageFileSize(fileSizeInMB);
      setValue("receipt", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const remove = () => {
    setReceiptImage("");
    setValue("receipt", null);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function resetFormField() {
    setBankIsSelected(false);
    setSelectedBank({});
    setValue("receipt", null);
    setBankAccountId("");
    setReceiptImage("");
    setAmountValue("");
  }

  const checkValueOnKeyDown = async (e) => {
    clearErrors();
    let tempAmountValue = amountValue;
    let keyedValue = tempAmountValue.substring(e.target.value);
    if (!isNaN(parseInt(keyedValue))) {
      tempAmountValue = amountValue + "" + keyedValue;
      setAmountValue(tempAmountValue);
    }
    else if (keyedValue == "Backspace" || keyedValue == "Delete") {
      tempAmountValue = amountValue.slice(0, -1);
      setAmountValue(tempAmountValue);
    }
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
    /// this checking below only works for desktop keyboards, mobile seems to have alot of bugs
    //if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
    //  tempAmountValue = amountValue + "" + e.key;
    //  setAmountValue(tempAmountValue);
    //}
    //else if (e.keyCode === 8 || e.keyCode === 46) //keyCode of backSpace and Del
    //{
    //  tempAmountValue = amountValue.slice(0, -1);
    //  setAmountValue(tempAmountValue);
    //}
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt) => {
    let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue)).toString();
    setAmountValue(
      updatedValue
    );
    clearErrors();
    let tempAmountValue = updatedValue;
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }


  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      amount: parseFloat(amountValue),
      channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
      panel: PanelType._MEMBERSITE,
      bankAccountId: bankAccountId,
      receiptReference: depositReference,
    };

    if (!stringIsNullOrEmpty(data.receipt)) {
      params["receipt"] = data.receipt;
    }

    if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
      params["bonusId"] = props.bonusDeposit;
    } else if (props.bonusDeposit === "-1") {
      params["bonusId"] = "";
    }

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setPaidAmount(params.amount);
      setIsReloadSuccess(true);
      setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
      props.checkPendingTxn();
      e.target.reset();
      resetFormField();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
    }
    _dispatch(setIdle());
  }

  const handleModalOnClick = () => {
    setIsReloadSuccess(false);
    //props.parentInit();
    init();
  };

  function setMinMaxButton(min, max, bankAccountId) {
    setMin(min);
    setMax(max);
    setBankAccountId(bankAccountId);
  }

  function copyText(label) {
    navigator.clipboard.writeText(label);
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };


  const handleBankSelection = async (bankAccount) => {
    setBankIsSelected(true);
    setSelectedBank(bankAccount);
    setMinMaxButton(bankAccount.minimumDeposit, bankAccount.maximumDeposit, bankAccount.id)
  }

  const checkAmountForErrors = async (tempAmountValue) => {
    clearErrors();
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  return (
    <>
      <form className="deposit-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group m-b-0">
          <div className="row">
            <div className="col-12">
              {bankIsSelected === false && (
                <>
                  <div className="all-normal-banks-container">
                    {bankAccountOption.length > 0 && (
                      <>
                        <div className="normal-bank-item-container">
                          <div className="font14 normal-parent-bank-title">
                            {t("BANK")}
                          </div>
                          <div className="deposit-normal-bank-list-container mt-3">
                            {bankAccountOption.map((child, index) => {
                              return (
                                <div
                                  key={"normal-deposit-bank" + index}
                                  className="deposit-normal-bank-list-items"
                                  onClick={() => { handleBankSelection(child) }}
                                >
                                  <img
                                    src={child.bankImg2}
                                    alt="normal payment banks"
                                    className="img-responsive"
                                  />
                                </div>
                              );
                            })
                            }
                          </div>
                        </div>
                        <hr className="custom-hr" />
                      </>
                    )}
                  </div>
                </>
              )}
              {bankIsSelected && (
                <>
                  <div className="font14 mb-3">
                    {t("SELECTED_BANK_METHOD")}
                  </div>
                  <div className="instant-provider-selected-wrapper mt-3" onClick={() => { setBankIsSelected(false); setSelectedBank({}); }}>
                    <div className="instant-provider active">
                      <img className="tick-image" src={TickIcon} alt="active" />
                      <img className="instant-provider-image" src={selectedBank.bankImg2} alt={selectedBank.bankName} />

                    </div>
                  </div>
                  <hr className="custom-hr" />
                  <div className="font14 mb-3">
                    {t("BANK_ACCOUNT_DETAILS") + " (" + selectedBank.bankName + ")"}
                  </div>
                  {bankAccountOption &&
                    bankAccountOption.length > 0 &&
                    bankIsSelected &&
                    <div className="deposit-normal-bank-details-container mt-3 mb-4">
                      <div className="deposit-normal-bank-details-item mt-2">
                        <div className="first">
                          {t("BANK_NAME")}
                        </div>
                        <div className="second">
                          <div>
                            {selectedBank.bankName}
                          </div>
                          <div className="copy" onClick={() => copyText(selectedBank.bankName)}>
                            <img
                              src={CopyIcon}
                              alt="i8 banks"
                              className="img-responsive"
                            />
                          </div>
                        </div>
                      </div>
                        <div className="deposit-normal-bank-details-item mt-2">
                          <div className="first">
                            {t("BANK_ACCOUNT_NUMBER")}
                          </div>
                          <div className="second">
                            <div>
                              {selectedBank.accountNumber}
                            </div>
                            <div className="copy" onClick={() => copyText(selectedBank.accountNumber)}>
                              <img
                                src={CopyIcon}
                                alt="i8 banks"
                                className="img-responsive"
                              />
                            </div>
                          </div>
                      </div>
                      <div className="deposit-normal-bank-details-item mt-2">
                        <div className="first">
                          {t("BANK_ACCOUNT_HOLDER_NAME")}
                        </div>
                        <div className="second">
                          <div>
                            {selectedBank.accountHolderName}
                          </div>
                          <div className="copy" onClick={() => copyText(selectedBank.accountHolderName)}>
                            <img
                              src={CopyIcon}
                              alt="i8 banks"
                              className="img-responsive"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="deposit-normal-bank-details-item mt-2">
                        <div className="first">
                          {t("MIN")}/{t("MAX")} {t("DEPOSIT")}
                        </div>
                        <div className="second">
                          THB {numberWithCommas(selectedBank.minimumDeposit)} - THB {numberWithCommas(selectedBank.maximumDeposit)}
                        </div>
                      </div>
                    </div>
                  }

                  <div className="form-group m-b-0">
                    <label className="col-form-label">
                      <b>{t("AMOUNT")} </b>
                    </label>
                    <div className="deposit-input-group mb-3">
                      <span className="currency-prefix">THB</span>
                      {<input
                        type="text"
                        className="form-white-input"
                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                        value={numberWithCommas(amountValue)}
                        onChange={(event) => {
                          let tempValue = event.target.value.replace(/[^0-9]/g, '');
                          setAmountValue(tempValue);
                          checkAmountForErrors(tempValue);
                        }}
                        onPaste={(event) => {
                          if (!stringIsNullOrEmpty(window.event.clipboardData.getData('text')) && (/^[1-9]{1,}[0-9]{0,}$/).test(window.event.clipboardData.getData('text'))) {
                            setAmountValue(parseInt(window.event.clipboardData.getData('text')));
                            event.preventDefault();
                          } else {
                            setAmountValue('');
                            event.preventDefault();
                          }
                        }}
                      />}
                      <div className="close-button">
                        <Icon
                          path={mdiClose}
                          size={1}
                          color="#FF0808"
                          onClick={() => {
                            setAmountValue("");
                          }}
                        />
                      </div>
                    </div>
                    {errors.amount && (
                      <div className="invalid-feedback">{t(errors.amount.message)}</div>
                    )}

                    {/*<div className="col-md-12">
                min != 0 && max != 0 && (<>
                  <span className="reminder">
                    * {t("MIN")} {numberWithCommas(min)} / {t("MAX")} {numberWithCommas(max)}
                  </span>
                </>)
              </div>*/}
                    <div className="col-md-12 font-11">
                      {(parseFloat(amountValue) >= min && !errors.amount)? 
                        <>
                          <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                          <span>{numberWithCurrencyFormat(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                          <i className="fas fa-arrow-right" />
                          <span>{" " + numberWithCurrencyFormat(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue) : 0), 2, true)}</span>
                        </>
                        :
                        <>
                        </>
                      }
                    </div>
                    <div className="amount-row">
                      {fixedAmount.map((amount, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            onClick={() => {
                              checkValueOnSelectFixedAmt(amount);
                            }}
                            className="btn custom-button"
                          >
                            + {numberWithCommas(amount)}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="withdrawal-name-alert alert alert-warning">
                    <div className="withdrawal-warning-text">
                      <img className="blue-warning-icon" src={BlueWarningIcon} />
                      {t("DEPOSIT_RECEIPT_REFERENCE")}
                    </div>
                  </div>
                  <div className="form-group m-b-15">
                    <label className="col-form-label">
                      <b>{t("RECEIPT")}</b>
                      {" (" + t("MAX_FILE_SIZE") + ": " + fileSizeLimit_InMB + "MB)"}
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name="receipt"
                        defaultValue={""}
                        render={({ onChange, value }) => {
                          return (
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                              maxSize={fileSizeLimit_InMB * ONE_MB_IN_BYTES}
                              onDropRejected={() =>
                                _dispatch(
                                  showResponseMessage(
                                    false,
                                    t("FILE_LARGER_THAN") +
                                    fileSizeLimit_InMB +
                                    "MB, " +
                                    t("PLEASE_TRY_AGAIN")
                                  )
                                )
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <section style={{ position: "relative" }}>
                                    {!stringIsNullOrEmpty(receiptImage) && (
                                      <div
                                        style={{
                                          background: "transparent",
                                          padding: "5px 10px",
                                          textAlign: "center",
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                        }}
                                        onClick={() => remove()}
                                      >
                                        <i className="fa fa-trash text-red"></i>
                                      </div>
                                    )}
                                    <div
                                      className="dropzone"
                                      style={{ minHeight: "200px", textAlign: "center" }}
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      {stringIsNullOrEmpty(receiptImage) && (
                                        <h4 style={{ color: "grey" }}>
                                          {t(
                                            "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                          )}
                                        </h4>
                                      )}
                                      {!stringIsNullOrEmpty(receiptImage) && (
                                        <aside className="thumbsContainer">
                                          <div className="thumb">
                                            <div className="thumbInner">
                                              <img
                                                src={receiptImage}
                                                className="dropzone-img"
                                                alt="dropzone-img"
                                              />
                                            </div>
                                          </div>
                                        </aside>
                                      )}
                                    </div>
                                  </section>
                                  {!stringIsNullOrEmpty(receiptImage) ? (
                                    <div style={{ color: "#002e6c", fontSize: "15px" }}>
                                      {t("UPLOADED_FILE_SIZE") +
                                        ": " +
                                        numberWithCurrencyFormat(imageFileSize, 2, true) +
                                        "MB"}
                                    </div>
                                  ) : (
                                    <div>&nbsp;</div>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-block btn-brand-gradient">
                    {t("SUBMIT")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>

      <Modal
        fade={false}
        contentClassName="modal-brand modal-center"
        id="modal-center"
        isOpen={isReloadSuccess}
        toggle={() => {
          setIsReloadSuccess(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-custom-header justify-content-center" style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "29px",
            color: "#EFEFEF"
          }}>
            <h2
              className="mb-0"
            >
              {t("RELOAD_SUCCESS")}
            </h2>
            <div className="text-center mt-4">
              <img
                src={require("../../assets/img/v3/approved-modal.png")}
                alt={t("APPROVED")}
              />
            </div>
            <div className="text-center mt-4">
              {t("PAID_DEPOSIT", { amount: paidAmount })}
            </div>
          </div>
          <table className="table text-brand table-reload-detail text-white">
            <tr>
              <th>{t("DEPOSIT_DATE_TIME")}</th>
              <th style={{ textAlign: "right" }}>{reloadDate}</th>
            </tr>
            <tr>
              <th>{t("REFERENCE_NUMBER")}</th>
              <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
            </tr>
          </table>
          <button
            className="btn btn-block btn-brand-gradient"
            onClick={() => {
              setIsReloadSuccess(false);
            }}
          >
            {t("DONE")}
          </button>
        </ModalBody>
      </Modal>
      <Numpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          setAmountValue(currentInput.value);
          setCurrentInput(e);
        }}
      />

      {showCopyAlert && (
        <div
          className="content content-full-width"
          style={{
            zIndex: "9999",
            position: "fixed",
            top: "5px",
            right: "5px",
            width: "200px",
          }}
        >
          <Alert color="green" style={{ textAlign: "center" }}>
            {t("COPY_SUCCESS")}
          </Alert>
        </div>
      )}
    </>
  );
};

export default NormalDeposit;
